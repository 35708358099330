import { createBrowserClient } from "@supabase/ssr";
import { cloneDeep } from "lodash";

export const createClient = () =>
  createBrowserClient(
    process.env.NEXT_PUBLIC_SUPABASE_URL,
    process.env.NEXT_PUBLIC_SUPABASE_ANON_KEY
  );

export async function handleSignOut() {
  const supabase = createClient();

  await supabase.auth.signOut();
}

export async function getSession() {
  const supabase = createClient();
  try {
    const {
      data: { session },
    } = await supabase.auth.getSession();
    return session;
  } catch (error) {
    console.error("Error:", error);
    return null;
  }
}

// export async function getProfile() {
//   const session = await getSession();
//   const supabase = createClient();

//   if (session?.user) {
//     const { data, error } = await supabase
//       .from("profiles")
//       .select(`username`)
//       .eq("id", session?.user.id)
//       .single();

//     if (error) {
//       return { error: true, message: error.message };
//     }

//     if (data) {
//       return {  data: data };
//     }
//   }
// }

export async function getStorageFiles(projectId) {
  const supabase = createClient();

  const {
    data: { session },
  } = await supabase.auth.getSession();

  try {
    const { data, error } = await supabase.storage
      .from("User projects")
      .list(`${session.user.id}/${projectId}/content`);

    if (error) {
      throw error;
    }

    if (data) {
      console.log("get storage", data);
      return data;
    }
  } catch (error) {
    console.log(error);
    return null;
  }
}

export async function removeStorageFiles(data, folderName) {
  const supabase = createClient();

  const {
    data: { user },
  } = await supabase.auth.getUser();

  if (data?.length > 0) {
    if (data.length === 1 && data[0].name === ".emptyFolderPlaceholder") {
      return;
    } else {
      const namesArray = data.map(({ name }) => `${user.id}/${folderName}/content/${name}`);
      namesArray.push(`${user.id}/${folderName}/thumbnail`);

      console.log("data to remove", namesArray);

      try {
        const { data, error } = await supabase.storage.from("User projects").remove(namesArray);

        if (error) {
          throw error;
        }
        if (data) console.log("all data removed", data);
      } catch (error) {
        console.log(error);
      }
    }
  } else {
    try {
      const { data, error } = await supabase.storage
        .from("User projects")
        .remove(`${user.id}/${folderName}/thumbnail`);

      if (error) {
        throw error;
      }
      if (data) console.log("only thumbnail removed", data);
    } catch (error) {
      console.log(error);
    }
  }
}

export async function uploadUserFiles(data) {
  const supabase = createClient();

  const {
    data: { user },
  } = await supabase.auth.getUser();

  const filesToAdd = {};
  const projectId = data.projectId;
  const thumbnail = data.thumbnail;

  Object.entries(data.structure2d).forEach(([keyname, value]) => {
    value.layers.forEach((layer, i) => {
      if ((layer.type === "image" || layer.type === "patternImage") && layer.subtype !== "svg") {
        const newObj = {
          path: `${user.id}/${projectId}/content/${keyname}-${i}`,
          src: layer.image,
        };

        data.uploadedImages.map((img) => {
          if (layer.image === img.src) {
            newObj.name = img.name;
            newObj.type = img.type;
            newObj.size = img.size;
          }
        });

        if (!hasNestedObjectWithValue(filesToAdd, "src", layer.image)) {
          filesToAdd[layer.image] = newObj;
        }
      }
    });
  });

  // Object.keys(data.structure2d).forEach((keyname) => {
  //   if (data.structure2d[keyname].layers.length > 0) {
  //     data.structure2d[keyname].layers.map((layer, i) => {
  //       if ((layer.type === "image" || layer.type === "patternImage") && layer.subtype !== "svg") {
  //         const newObj = {
  //           name: `${user.id}/${projectId}/content/${keyname}-${i}`,
  //           src: layer.image,
  //         };

  //         if (!hasNestedObjectWithValue(filesToAdd, "src", layer.image)) {
  //           filesToAdd[layer.image] = newObj;
  //         }
  //       }
  //     });
  //   }
  // });

  Object.keys(filesToAdd).map((keyname) => {
    convertAndUpload(filesToAdd[keyname].src, filesToAdd[keyname].path, projectId);
  });

  uploadThumbnail(thumbnail, projectId);

  return filesToAdd;
}

export async function downloadProjectData(file) {
  const supabase = createClient();

  try {
    const { data, error } = await supabase.storage.from("User projects").download(file);

    if (data) return data;
  } catch (error) {
    console.log(error);
  }
}

export async function downloadProjectDataTest(path) {
  const supabase = createClient();

  try {
    const { data, error } = await supabase.storage.from("User projects").download(path);

    if (error) throw error;
    if (data) return data;
  } catch (error) {
    console.log(error);
  }
}

export async function handleSignInWithGoogle() {
  const supabase = createClient();

  try {
    await supabase.auth.signInWithOAuth({
      provider: "google",
      options: {
        skipBrowserRedirect: false,
        redirectTo: `${location.origin}/auth/callback`,
        queryParams: {
          access_type: "offline",
          prompt: "consent",
        },
      },
    });
  } catch (error) {
    throw error;
  }
}

// helpers

async function convertAndUpload(img, name, path) {
  const blob = await objectURLToBlob(img);

  try {
    await uploadImage(blob, name, path);
  } catch (error) {
    console.error("Error:", error);
  } finally {
    // console.log("upload done");
  }
}

async function objectURLToBlob(objectURL) {
  try {
    const response = await fetch(objectURL);
    const blob = await response.blob();
    return blob;
  } catch (error) {
    console.error(error);
    throw error; // Re-throw the error for handling at a higher level
  }
}

async function uploadImage(blob, name, path) {
  const supabase = createClient();

  const {
    data: { session },
  } = await supabase.auth.getSession();

  try {
    const { error: uploadError } = await supabase.storage.from("User projects").upload(name, blob, {
      upsert: true,
    });

    if (uploadError) {
      throw uploadError;
    }
  } catch (error) {
    console.log(error);
  }
}

async function uploadThumbnail(blob, path) {
  const supabase = createClient();
  const image = await objectURLToBlob(blob);

  const {
    data: { user },
  } = await supabase.auth.getUser();

  try {
    const { error: uploadError } = await supabase.storage
      .from("User projects")
      .upload(`${user.id}/${path}/thumbnail`, image, {
        upsert: true,
      });

    if (uploadError) {
      throw uploadError;
    }
  } catch (error) {
    console.log(error);
  }
}

const hasNestedObjectWithValue = (obj, property, targetValue) => {
  return Object.values(obj).some((nestedObj) => nestedObj[property] === targetValue);
};
