import React, { useState, useRef } from "react";
import { ButtonSVG, ButtonTxt, FlexWrapper, AlertDialog } from "../UI/UIComponents";
import { handlePasswordReset, handleSignIn, handleSignUp } from "@/actions/supabase/server";
import { handleSignInWithGoogle } from "@/actions/supabase/client";
import toast from "react-hot-toast";
import Link from "next/link";
import { GoogleIcon } from "../Icons";
import Image from "next/image";

function SignUp({ hideAuth, setShowSignup, onSuccess }) {
  const [isLoading, setIsLoading] = useState(false);

  const signUp = async (formData) => {
    setIsLoading(true);

    const rawFormData = {
      email: formData.get("email"),
      password: formData.get("password"),
      fullName: formData.get("fullName"),
      userName: formData.get("userName"),
    };

    const res = await handleSignUp(rawFormData);

    if (res.isError) {
      toast.error(res.message);
    } else {
      onSuccess();
    }

    setIsLoading(false);
  };

  return (
    <>
      <form action={signUp}>
        <FlexWrapper padding="0" direction="column">
          <FlexWrapper padding="0" direction="column" gap="var(--gap-3)">
            <label htmlFor="fullName">Full name</label>
            <input required type="text" name="fullName" id="fullName" placeholder="John Doe" />
          </FlexWrapper>
          <FlexWrapper padding="0" direction="column" gap="var(--gap-3)">
            <label htmlFor="userName">Username</label>
            <input required type="text" name="userName" id="userName" placeholder="Username" />
          </FlexWrapper>
          <FlexWrapper padding="0" direction="column" gap="var(--gap-3)">
            <label htmlFor="email">Email</label>
            <input required type="email" name="email" id="email" placeholder="example@mail.com" />
          </FlexWrapper>
          <PasswordInput />
          <ButtonTxt isLoading={isLoading} type="submit" primary text="Create account" />
          <FlexWrapper padding="0" justifyContent="center">
            Or
          </FlexWrapper>
          <ButtonSVG text="Sign in with Google" callback={handleSignInWithGoogle}>
            <GoogleIcon />
          </ButtonSVG>
        </FlexWrapper>
      </form>
      <FlexWrapper direction="column" padding="0">
        <span style={{ textAlign: "center" }}>
          Already a member?{" "}
          <span onClick={() => setShowSignup(false)} className="link">
            Sign in.
          </span>
        </span>
      </FlexWrapper>
    </>
  );
}

function SignIn({ hideAuth, setShowSignup }) {
  const [isLoading, setIsLoading] = useState(false);

  const signIn = async (formData) => {
    setIsLoading(true);

    const rawFormData = {
      email: formData.get("email"),
      password: formData.get("password"),
    };

    const error = await handleSignIn(rawFormData);

    if (error) {
      toast.error(error);
    } else {
      toast.success("Signed in.");
      hideAuth();
    }

    setIsLoading(false);
  };

  return (
    <>
      <form action={signIn}>
        <FlexWrapper padding="0" direction="column">
          <FlexWrapper padding="0" direction="column" gap="var(--gap-3)">
            <label htmlFor="email">Email</label>
            <input required type="email" name="email" id="email" placeholder="example@mail.com" />
          </FlexWrapper>
          <PasswordInput />
          <ButtonTxt type="submit" isLoading={isLoading} primary text="Sign in" />
          <FlexWrapper padding="0" justifyContent="center">
            Or
          </FlexWrapper>
          <ButtonSVG text="Sign in with Google" callback={handleSignInWithGoogle}>
            <GoogleIcon />
          </ButtonSVG>
        </FlexWrapper>
      </form>
      <FlexWrapper direction="column" padding="0">
        <span style={{ textAlign: "center" }}>
          No account?{" "}
          <span onClick={() => setShowSignup(true)} className="link">
            Let&apos;s create one.
          </span>
        </span>
      </FlexWrapper>
    </>
  );
}

export default function AuthUI({ visible, hideAuth }) {
  const [showSignup, setShowSignup] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [showPasswordReset, setShowPasswordReset] = useState(false);
  const [email, setEmail] = useState("");

  const onPasswordReset = async () => {
    const error = await handlePasswordReset(email, `${location.origin}/auth/confirm`);

    if (error) {
      toast.error(error);
    } else {
      toast.success("Password reset link sent.");
      setShowPasswordReset(false);
    }
  };

  return (
    <>
      <AlertDialog
        visible={visible}
        onClose={hideAuth}
        header={showSignup ? "Create account" : "Sign in"}
        body="Fill out the form and we will take it from here."
        icon={<KeyIcon />}
      >
        {showSignup ? (
          <SignUp
            hideAuth={hideAuth}
            setShowSignup={setShowSignup}
            onSuccess={() => setShowConfirm(true)}
          />
        ) : (
          <SignIn hideAuth={hideAuth} setShowSignup={setShowSignup} />
        )}
        <span onClick={() => setShowPasswordReset(true)} className="link text-center">
          Forgot password?
        </span>
        <FlexWrapper
          direction="column"
          padding="0"
          alignItems="center"
          gap="0"
          style={{ textAlign: "center" }}
        >
          By continuing, you agree to our{" "}
          <Link href="/legal" className="link">
            Terms and Privacy policy.
          </Link>
        </FlexWrapper>
      </AlertDialog>
      <AlertDialog
        visible={showConfirm}
        onClose={() => setShowConfirm(false)}
        header="Confirm your email"
        body="Check your email for confirmation."
      >
        <ButtonTxt
          callback={() => {
            setShowConfirm(false);
            hideAuth();
          }}
          text="Gotcha"
        />
      </AlertDialog>
      <AlertDialog
        visible={showPasswordReset}
        onClose={() => setShowPasswordReset(false)}
        header="Reset password"
        body="One time login link will be sent to your email address."
      >
        <FlexWrapper padding="0" direction="column" gap="var(--gap-3)">
          <label htmlFor="emailReset">Email</label>
          <input
            required
            type="email"
            name="email"
            id="emailReset"
            placeholder="example@mail.com"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </FlexWrapper>
        <ButtonTxt primary callback={onPasswordReset} text="Reset password" />
      </AlertDialog>
    </>
  );
}

function PasswordInput() {
  const ref = useRef();

  const handleShowPassword = (e) => {
    e.preventDefault();
    const type = ref.current.type;

    if (type === "password") {
      ref.current.type = "text";
    } else {
      ref.current.type = "password";
    }
  };

  return (
    <FlexWrapper padding="0" direction="column" gap="var(--gap-3)">
      <label htmlFor="password">Password</label>
      <div style={{ position: "relative", display: "flex" }}>
        <input
          ref={ref}
          style={{ flex: 1 }}
          required
          type="password"
          name="password"
          id="password"
          placeholder="Password"
        />
        <div
          style={{
            position: "absolute",
            right: 0,
            top: 0,
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ButtonSVG noHover callback={handleShowPassword}>
            <svg
              width="15"
              height="15"
              viewBox="0 0 15 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.50018 5.62813C8.16322 5.62813 8.7991 5.89152 9.26794 6.36036C9.73679 6.8292 10.0002 7.46508 10.0002 8.12813C10.0002 8.79117 9.73679 9.42705 9.26794 9.89589C8.7991 10.3647 8.16322 10.6281 7.50018 10.6281C6.83714 10.6281 6.20125 10.3647 5.73241 9.89589C5.26357 9.42705 5.00018 8.79117 5.00018 8.12813C5.00018 7.46508 5.26357 6.8292 5.73241 6.36036C6.20125 5.89152 6.83714 5.62813 7.50018 5.62813ZM7.50018 6.56563C7.08578 6.56563 6.68835 6.73025 6.39532 7.02327C6.1023 7.3163 5.93768 7.71372 5.93768 8.12813C5.93768 8.54253 6.1023 8.93995 6.39532 9.23298C6.68835 9.52601 7.08578 9.69063 7.50018 9.69063C7.91458 9.69063 8.31201 9.52601 8.60503 9.23298C8.89806 8.93995 9.06268 8.54253 9.06268 8.12813C9.06268 7.71372 8.89806 7.3163 8.60503 7.02327C8.31201 6.73025 7.91458 6.56563 7.50018 6.56563ZM7.50018 3.4375C10.3833 3.4375 12.8727 5.40625 13.5633 8.165C13.5936 8.28559 13.5747 8.41326 13.5108 8.51992C13.4469 8.62658 13.3433 8.7035 13.2227 8.73375C13.1021 8.764 12.9744 8.74511 12.8678 8.68123C12.7611 8.61735 12.6842 8.51372 12.6539 8.39312C12.3646 7.24529 11.7003 6.22691 10.7664 5.49946C9.8326 4.772 8.68264 4.37711 7.49889 4.3774C6.31515 4.37768 5.16537 4.77313 4.23188 5.50104C3.29839 6.22895 2.63462 7.24765 2.3458 8.39562C2.33091 8.45538 2.30439 8.51161 2.26776 8.56111C2.23113 8.61061 2.18511 8.65242 2.13232 8.68413C2.07954 8.71585 2.02102 8.73686 1.96012 8.74596C1.89922 8.75507 1.83712 8.75208 1.77736 8.73719C1.71761 8.72229 1.66138 8.69577 1.61188 8.65914C1.56238 8.62251 1.52057 8.57649 1.48886 8.52371C1.45714 8.47092 1.43613 8.41241 1.42703 8.35151C1.41792 8.2906 1.42091 8.2285 1.4358 8.16875C1.77475 6.81706 2.55558 5.61736 3.6543 4.76017C4.75302 3.90298 6.10664 3.43744 7.50018 3.4375Z"
                fill="black"
              />
            </svg>
          </ButtonSVG>
        </div>
      </div>
    </FlexWrapper>
  );
}

function KeyIcon() {
  return (
    <svg
      style={{ width: "30px", height: "30px" }}
      width="18"
      height="22"
      viewBox="0 0 18 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.71859 0.886317C6.73291 0.475731 7.84102 0.25 9 0.25C13.8325 0.25 17.75 4.16751 17.75 9C17.75 9.41421 17.4142 9.75 17 9.75C16.5858 9.75 16.25 9.41421 16.25 9C16.25 4.99593 13.0041 1.75 9 1.75C8.03718 1.75 7.11995 1.93729 6.28141 2.27672C5.89746 2.43214 5.46022 2.24688 5.3048 1.86293C5.14938 1.47898 5.33464 1.04174 5.71859 0.886317ZM3.22373 3.41481C3.54692 3.67389 3.59889 4.14591 3.33982 4.4691C2.34472 5.71044 1.75 7.28475 1.75 9V13C1.75 16.2117 3.83888 18.9375 6.73419 19.8891C7.12769 20.0184 7.34184 20.4423 7.2125 20.8358C7.08316 21.2293 6.65931 21.4434 6.26581 21.3141C2.77326 20.1661 0.25 16.8787 0.25 13V9C0.25 6.93147 0.968718 5.02876 2.16944 3.5309C2.42852 3.20771 2.90054 3.15574 3.22373 3.41481ZM9 5.75C7.20507 5.75 5.75 7.20507 5.75 9V13C5.75 13.4142 5.41421 13.75 5 13.75C4.58579 13.75 4.25 13.4142 4.25 13V9C4.25 6.37665 6.37665 4.25 9 4.25C9.41421 4.25 9.75 4.58579 9.75 5C9.75 5.41421 9.41421 5.75 9 5.75ZM12.0894 6.35078C12.4479 6.14339 12.9067 6.26593 13.1141 6.62449C13.5187 7.32389 13.75 8.13606 13.75 9V13C13.75 15.6233 11.6233 17.75 9 17.75C8.13604 17.75 7.32384 17.5187 6.62447 17.1141C6.26592 16.9067 6.14339 16.4479 6.35079 16.0894C6.55819 15.7308 7.01698 15.6083 7.37553 15.8157C7.85276 16.0917 8.40676 16.25 9 16.25C10.7949 16.25 12.25 14.7949 12.25 13V9C12.25 8.4068 12.0917 7.85281 11.8157 7.37551C11.6083 7.01695 11.7308 6.55816 12.0894 6.35078ZM9 8.25C9.41421 8.25 9.75 8.58579 9.75 9V13C9.75 13.4142 9.41421 13.75 9 13.75C8.58579 13.75 8.25 13.4142 8.25 13V9C8.25 8.58579 8.58579 8.25 9 8.25ZM17 12.25C17.4142 12.25 17.75 12.5858 17.75 13C17.75 16.8787 15.2267 20.1661 11.7342 21.3141C11.3407 21.4434 10.9168 21.2293 10.7875 20.8358C10.6582 20.4423 10.8723 20.0184 11.2658 19.8891C14.1611 18.9375 16.25 16.2117 16.25 13C16.25 12.5858 16.5858 12.25 17 12.25Z"
        fill="var(--primary-text-color)"
      />
    </svg>
  );
}
