"use client";

import React, { useState, useEffect, useRef } from "react";
import {
  ButtonTxt,
  FlexWrapper,
  ButtonSVG,
  Status,
  AlertDialog,
} from "@/components/UI/UIComponents";
import { useRouter } from "next/navigation";
import { handlePasswordUpdate, handleSignOut } from "@/actions/supabase/server";
import { useSelector } from "react-redux";
import AuthUI from "./auth/Authorization";
import styles from "../components/UI/Nav.module.css";
import Image from "next/image";
import Link from "next/link";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import dynamic from "next/dynamic";
import { updateProfile } from "@/actions/supabase/server";
import { manageBilling } from "@/actions/stripe/server";
import toast from "react-hot-toast";
import { UserIcon } from "./Icons";
import ThemeButton from "./themeButton/ThemeButton";

const DynamicProductsList = dynamic(() => import("./ProductsList"), {
  ssr: false,
});

function removeCharactersAfterAt(inputString) {
  var atIndex = inputString.indexOf("@");
  if (atIndex !== -1) {
    // If "@" is found, extract the substring before it
    return inputString.substring(0, atIndex);
  } else {
    // If "@" is not found, return the original string
    return inputString;
  }
}

export default function Nav({ user }) {
  const { theme } = useSelector((state) => state.theme);

  const router = useRouter();

  const [showAuth, setShowAuth] = useState(false);
  const [isMobile, setIsMobile] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const [menuBg, setMenuBg] = useState("");

  const [isActive, setIsActive] = useState(null);

  const [showProducts, setShowProducts] = useState(false);

  const nav = useRef();

  useGSAP(
    () => {
      if (menuOpen) {
        gsap.to(nav.current, {
          height: "100dvh",
          backdropFilter: "blur(50px)",
          background: "transparent",
        });
      } else {
        gsap.to(nav.current, {
          height: "auto",
          backdropFilter: "blur(0px)",
          background: menuBg,
        });
      }
    },
    { scope: nav, dependencies: [menuOpen, menuBg] }
  );

  useEffect(() => {
    if (user) {
      setIsActive(
        !user?.subscriptions?.end_at ? false : new Date(user.subscriptions.end_at) > new Date()
      );
    }
  }, [user]);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(width <= 1330px)");

    if (mediaQuery.matches) {
      setIsMobile(true);
      setMenuBg("var(--bg-color)");
    } else {
      setIsMobile(false);
      setMenuBg("transparent");
    }
  }, []);

  const onSignOut = async () => {
    handleSignOut().then(() => {
      setMenuOpen(false);
      router.refresh();
    });
  };

  const handleMenu = () => {
    setMenuOpen((prev) => !prev);
  };

  return (
    <>
      <AuthUI visible={showAuth} hideAuth={() => setShowAuth(false)} />
      {showProducts && (
        <DynamicProductsList
          showProducts={showProducts}
          user={user}
          onClose={() => setShowProducts(false)}
        />
      )}
      <nav
        ref={nav}
        className={styles.nav}
        style={{
          background: menuBg,
        }}
      >
        <FlexWrapper
          padding="var(--padding-1) 5vw"
          justifyContent="space-between"
          direction="column"
          extra={{ flex: 1, width: "100%" }}
        >
          <FlexWrapper
            justifyContent="space-between"
            alignItems="flex-start"
            direction="row"
            padding="0"
            extra={{ width: "100%" }}
          >
            <FlexWrapper padding="0" justifyContent="flex-start">
              <div
                style={{
                  width: "clamp(40px, 7vw, 50px)",
                  height: "50px",
                  position: "relative",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setMenuOpen(false);
                  router.push("/", { scroll: true });
                }}
              >
                {theme === "light" ? (
                  <Image
                    src="/logo_dark.svg"
                    style={{ objectFit: "contain" }}
                    fill
                    alt="logo"
                    sizes="10vw"
                  />
                ) : (
                  <Image
                    src="/logo_light.svg"
                    style={{ objectFit: "contain" }}
                    fill
                    alt="logo"
                    sizes="10vw"
                  />
                )}
              </div>
            </FlexWrapper>
            <FlexWrapper gap="var(--gap-3)" padding="0">
              <ThemeButton />
              {user ? (
                <ButtonSVG
                  text={user.username ? user.username : removeCharactersAfterAt(user.email)}
                  callback={handleMenu}
                  state={menuOpen}
                >
                  <UserIcon />
                </ButtonSVG>
              ) : (
                <ButtonSVG state={menuOpen} text="Menu" callback={handleMenu}>
                  <svg
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.49951 5.67773C7.85973 5.67773 8.21185 5.78455 8.51136 5.98468C8.81087 6.1848 9.04431 6.46925 9.18216 6.80205C9.32001 7.13484 9.35608 7.50104 9.2858 7.85434C9.21553 8.20763 9.04207 8.53216 8.78735 8.78687C8.53264 9.04158 8.20812 9.21504 7.85482 9.28532C7.50153 9.35559 7.13533 9.31952 6.80253 9.18167C6.46974 9.04383 6.18529 8.81039 5.98516 8.51088C5.78504 8.21137 5.67822 7.85924 5.67822 7.49902C5.67822 7.01599 5.87011 6.55274 6.21166 6.21118C6.55322 5.86962 7.01647 5.67773 7.49951 5.67773ZM1.125 7.49902C1.125 7.85924 1.23182 8.21137 1.43194 8.51088C1.63207 8.81039 1.91651 9.04383 2.24931 9.18167C2.58211 9.31952 2.94831 9.35559 3.3016 9.28532C3.6549 9.21504 3.97942 9.04158 4.23413 8.78687C4.48885 8.53216 4.66231 8.20763 4.73258 7.85434C4.80286 7.50104 4.76679 7.13484 4.62894 6.80205C4.49109 6.46925 4.25765 6.1848 3.95814 5.98468C3.65863 5.78455 3.3065 5.67773 2.94629 5.67773C2.46325 5.67773 2 5.86962 1.65844 6.21118C1.31689 6.55274 1.125 7.01599 1.125 7.49902ZM10.2314 7.49902C10.2314 7.85924 10.3383 8.21137 10.5384 8.51088C10.7385 8.81039 11.023 9.04382 11.3558 9.18167C11.6886 9.31952 12.0548 9.35559 12.408 9.28532C12.7613 9.21504 13.0859 9.04158 13.3406 8.78687C13.5953 8.53216 13.7687 8.20763 13.839 7.85434C13.9093 7.50104 13.8732 7.13484 13.7354 6.80205C13.5975 6.46925 13.3641 6.1848 13.0646 5.98468C12.7651 5.78455 12.4129 5.67773 12.0527 5.67773C11.5697 5.67773 11.1064 5.86962 10.7649 6.21118C10.4233 6.55274 10.2314 7.01599 10.2314 7.49902Z"
                      fill="var(--primary-text-color)"
                    />
                  </svg>
                </ButtonSVG>
              )}
            </FlexWrapper>
          </FlexWrapper>
          <>
            {menuOpen && (
              <NavInner
                isActive={isActive}
                isMobile={isMobile}
                onSignOut={onSignOut}
                onLinkClick={handleMenu}
                user={user}
                showAuth={() => setShowAuth(true)}
                setShowProducts={setShowProducts}
                menuOpen={menuOpen}
              />
            )}
          </>
        </FlexWrapper>
      </nav>
    </>
  );
}

function NavInner({ isActive, menuOpen, showAuth, user, onLinkClick, onSignOut, setShowProducts }) {
  const ref = useRef();

  const [showProfile, setShowProfile] = useState(false);

  useGSAP(
    () => {
      if (menuOpen) {
        gsap
          .timeline({ delay: 0.5 })
          .to(".nav-category", {
            opacity: 1,
            stagger: 0.1,
          })
          .to(
            ".link",
            {
              opacity: 1,
              yPercent: -100,
              stagger: 0.1,
            },
            "<"
          );
      } else {
        gsap
          .timeline({ delay: 0.5 })
          .to(".nav-category", {
            opacity: 0,
            stagger: 0.1,
          })
          .to(
            ".link",
            {
              opacity: 0,
              yPercent: 0,
              stagger: 0.1,
            },
            "<"
          );
      }
    },
    {
      scope: ref,
      dependencies: [menuOpen, user],
    }
  );

  return (
    <>
      <Profile user={user} showProfile={showProfile} onClose={() => setShowProfile(false)} />
      <div
        ref={ref}
        style={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          width: "100%",
          position: "relative",
        }}
      >
        <FlexWrapper
          padding="0"
          gap="5vw"
          alignItems={"flex-start"}
          justifyContent="flex-start"
          extra={{ width: "100%", flexWrap: "wrap" }}
        >
          <FlexWrapper
            padding="0"
            direction="column"
            gap="var(--gap-1)"
            alignItems={"flex-start"}
            justifyContent="flex-start"
          >
            <span className="nav-category" style={{ opacity: 0 }}>
              + General
            </span>
            <FlexWrapper padding="0" direction="column" gap="var(--gap-3)">
              {!user && (
                <span
                  className="medium-heading link"
                  style={{
                    opacity: 0,
                    transform: "translateY(100%)",
                    color: "var(--active-btn-color)",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    showAuth();
                  }}
                >
                  Sign up
                </span>
              )}
              <Link
                href="/#about"
                className="medium-heading link"
                style={{ opacity: 0, transform: "translateY(100%)" }}
                onClick={onLinkClick}
              >
                About
              </Link>
              <span
                className="medium-heading link"
                style={{ opacity: 0, transform: "translateY(100%)", cursor: "pointer" }}
                onClick={() => {
                  setShowProducts(true);
                  onLinkClick();
                }}
              >
                Products
              </span>
              <Link
                href="/help-center"
                className="medium-heading link"
                style={{ opacity: 0, transform: "translateY(100%)" }}
                onClick={onLinkClick}
                prefetch={false}
              >
                Help center
              </Link>
              <Link
                href="/legal"
                className="medium-heading link"
                style={{ opacity: 0, transform: "translateY(100%)" }}
                onClick={onLinkClick}
              >
                Legal
              </Link>
            </FlexWrapper>
          </FlexWrapper>
          {user && (
            <>
              <FlexWrapper
                padding="0"
                direction="column"
                gap="var(--gap-1)"
                alignItems={"flex-start"}
                justifyContent="flex-start"
              >
                <span className="nav-category" style={{ opacity: 0 }}>
                  + Mockups
                </span>
                <FlexWrapper padding="0" direction="column" gap="var(--gap-3)">
                  <span
                    className="medium-heading link"
                    style={{
                      opacity: 0,
                      transform: "translateY(100%)",
                      cursor: "pointer",
                    }}
                    onClick={() => setShowProducts(true)}
                  >
                    Create new
                    <span
                      style={{
                        fontFamily: "var(--geist-ultra-light)",
                        color: "var(--active-btn-color)",
                      }}
                    >
                      +
                    </span>
                  </span>
                  <Link
                    href="/home/projects"
                    className="medium-heading link"
                    style={{ opacity: 0, transform: "translateY(100%)" }}
                    onClick={onLinkClick}
                  >
                    My projects
                  </Link>
                </FlexWrapper>
              </FlexWrapper>
              <FlexWrapper
                padding="0"
                direction="column"
                gap="var(--gap-1)"
                alignItems={"flex-start"}
                justifyContent="flex-start"
              >
                <span className="nav-category" style={{ opacity: 0 }}>
                  + Account
                </span>
                <FlexWrapper padding="0" direction="column" gap="var(--gap-3)">
                  <span
                    href="/home"
                    className="medium-heading link"
                    style={{
                      opacity: 0,
                      transform: "translateY(100%)",
                    }}
                    onClick={() => setShowProfile(true)}
                  >
                    My profile
                  </span>
                  <span
                    className="medium-heading link"
                    style={{
                      opacity: 0,
                      transform: "translateY(100%)",
                      cursor: "pointer",
                      color: "var(--active-btn-color)",
                    }}
                    onClick={onSignOut}
                  >
                    Sign out
                  </span>
                </FlexWrapper>
              </FlexWrapper>
            </>
          )}
        </FlexWrapper>
        <FlexWrapper padding="0" extra={{ flex: 1 }}>
          <NavExtra menuOpen={menuOpen} />
        </FlexWrapper>
      </div>
    </>
  );
}

function NavExtra({ menuOpen }) {
  const ref = useRef();

  useGSAP(
    () => {
      if (menuOpen) {
        gsap.to(ref.current, {
          opacity: 1,
          delay: 0.5,
        });
      } else {
        gsap.to(ref.current, {
          opacity: 0,
          delay: 0.5,
        });
      }
    },
    {
      scope: ref,
      dependencies: [menuOpen],
    }
  );

  return (
    <div
      ref={ref}
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-end",
        width: "100%",
        opacity: 0,
      }}
    >
      <FlexWrapper direction="column" gap="var(--gap-1)" padding="0">
        <FlexWrapper direction="column" gap="0" padding="0">
          <span>©2024 Wannabe</span>
          <span>All rights reserved</span>
        </FlexWrapper>
      </FlexWrapper>
    </div>
  );
}

function Profile({ user, showProfile, onClose }) {
  const [username, setusername] = useState();
  const [fullname, setfullname] = useState();
  const [password, setpassword] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (user) {
      setusername(user.username);
      setfullname(user.full_name);
    }
  }, [user]);

  const handleUpdateProfile = async () => {
    setIsLoading(true);
    const error = await updateProfile(username, fullname);

    if (error) {
      toast.error("Could not update");
    } else {
      toast.success("Updated");
    }

    setIsLoading(false);
  };

  const onPasswordUpdate = async () => {
    setIsLoading(true);
    const error = await handlePasswordUpdate(password);

    if (error) {
      toast.error(error);
    } else {
      toast.success("Password updated");
    }
    setIsLoading(false);
  };

  return (
    <AlertDialog
      visible={showProfile}
      onClose={onClose}
      header="User details"
      icon={<ProfileIcon />}
    >
      <FlexWrapper padding="0" direction="column">
        <FlexWrapper padding="0" direction="column" gap="var(--gap-3)">
          <label htmlFor="email">Email</label>
          <input id="email" type="text" defaultValue={user?.email} disabled />
        </FlexWrapper>
        <FlexWrapper padding="0" direction="column" gap="var(--gap-3)">
          <label htmlFor="fullName">Fullname</label>
          <input
            id="fullName"
            name="fullname"
            type="text"
            onChange={(e) => setfullname(e.target.value)}
            value={fullname}
          />
        </FlexWrapper>
        <FlexWrapper padding="0" direction="column" gap="var(--gap-3)">
          <label htmlFor="username">Username</label>
          <input
            id="username"
            name="username"
            type="text"
            onChange={(e) => setusername(e.target.value)}
            value={username}
          />
        </FlexWrapper>
        <ButtonTxt isLoading={isLoading} text="Update profile" callback={handleUpdateProfile} />
      </FlexWrapper>
      <FlexWrapper padding="0" direction="column">
        <FlexWrapper padding="0" direction="column" gap="var(--gap-3)">
          <label htmlFor="passwordUpdate">Password</label>
          <input
            id="passwordUpdate"
            name="passwordUpdate"
            type="password"
            onChange={(e) => setpassword(e.target.value)}
            value={password}
          />
        </FlexWrapper>
        <ButtonTxt isLoading={isLoading} text="Update password" callback={onPasswordUpdate} />
      </FlexWrapper>
    </AlertDialog>
  );
}

function ProfileIcon() {
  return (
    <svg
      style={{ width: "30px", height: "30px" }}
      width="18"
      height="22"
      viewBox="0 0 18 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.8 13.75C3.56324 13.75 1.75 15.5632 1.75 17.8C1.75 19.1531 2.84691 20.25 4.2 20.25H13.8C15.1531 20.25 16.25 19.1531 16.25 17.8C16.25 15.5632 14.4368 13.75 12.2 13.75H5.8ZM0.25 17.8C0.25 14.7348 2.73482 12.25 5.8 12.25H12.2C15.2652 12.25 17.75 14.7348 17.75 17.8C17.75 19.9815 15.9815 21.75 13.8 21.75H4.2C2.01847 21.75 0.25 19.9815 0.25 17.8Z"
        fill="var(--primary-text-color)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 1.75C7.20507 1.75 5.75 3.20507 5.75 5C5.75 6.79493 7.20507 8.25 9 8.25C10.7949 8.25 12.25 6.79492 12.25 5C12.25 3.20508 10.7949 1.75 9 1.75ZM4.25 5C4.25 2.37665 6.37665 0.25 9 0.25C11.6233 0.25 13.75 2.37664 13.75 5C13.75 7.62336 11.6233 9.75 9 9.75C6.37665 9.75 4.25 7.62335 4.25 5Z"
        fill="var(--primary-text-color)"
      />
    </svg>
  );
}
