import { getCookie, eraseCookie, setCookie } from "@/misc/utils";

const THEME_DARK = "dark";
const THEME_LIGHT = "light";
const THEME_STORAGE_KEY = "theme-preference";

const applyPreference = (theme) => {
  const root = document.firstElementChild;
  root.classList.remove(THEME_LIGHT, THEME_DARK);
  root.classList.add(theme);
  root.style.colorScheme = theme;
};

export const getPreference = () => {
  const cookie = getCookie(THEME_STORAGE_KEY);

  if (cookie) {
    return cookie;
  }

  return window.matchMedia(`(prefers-color-scheme: ${THEME_DARK})`).matches
    ? THEME_DARK
    : THEME_LIGHT;
};

export const setPreference = (theme) => {
  eraseCookie(THEME_STORAGE_KEY);
  setCookie(THEME_STORAGE_KEY, theme, 365);
  applyPreference(theme);
};

export const getColors = () => ({ dark: THEME_DARK, light: THEME_LIGHT });
