"use client";

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ButtonSVG } from "../UI/UIComponents";
import { MoonIcon, SunIcon } from "../Icons";
import { setTheme } from "@/features/themeSlice";
import { getPreference, setPreference } from "./themeHelper";

export default function ThemeButton() {
  const dispatch = useDispatch();
  const { theme } = useSelector((state) => state.theme);

  useEffect(() => {
    const uiTheme = getPreference("theme-preference");

    if (uiTheme === "light") {
      setThemeLight();
    } else {
      setThemeDark();
    }
  }, []);

  const setThemeLight = () => {
    setPreference("light");
    dispatch(setTheme("light"));
  };

  const setThemeDark = () => {
    setPreference("dark");
    dispatch(setTheme("dark"));
  };

  const handleTheme = () => {
    if (theme === "dark") setThemeLight();
    if (theme === "light") setThemeDark();
  };

  return (
    <ButtonSVG callback={handleTheme}>{theme === "dark" ? <MoonIcon /> : <SunIcon />}</ButtonSVG>
  );
}
